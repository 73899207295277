"use client"

import Link, { LinkProps } from "next/link"
import { CSSProperties } from "react"
import { useTgInviteLink } from "shared/api"

interface ITelegramLink extends Omit<LinkProps, "href"> {
  children: React.ReactNode
  className?: string
  style?: CSSProperties
  htmlId?: string
  target?: "_blank" | "_self" | "_parent" | "_top" | string
  typeLink?: "bot" | "channel"
}

export const TelegramLink = ({ typeLink = "bot", ...props }: ITelegramLink) => {
  const { inviteLinkUrl, botStartUrl } = useTgInviteLink()

  return (
    <Link
      href={typeLink === "channel" ? inviteLinkUrl : botStartUrl}
      className={props.className}
      style={props.style}
      id={props.htmlId}
      {...props}
    >
      {props.children}
    </Link>
  )
}
